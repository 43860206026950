@value black, black-lightest from '../../colors.module.css';
@value box-shadow-base from '../../shadows.module.css';

.blogPost {
  composes: typography-body from global;
  margin: space(10) 0;
}

.publishedAt {
  composes: typography-textBlock-paragraph-tagline from global;
  margin: space(4) 0 0;
}

.heading {
  composes: typography-headingXs from global;
}

h3.heading {
  margin: 0 0 space(2) 0;
}

.heading a {
  color: inherit;
  text-decoration: none;
}

.heading a:hover,
.heading a:active {
  text-decoration: underline;
}

.summary {
  margin: 0;
}

.readMore {
  composes: typography-navigationSm from global;
  display: inline-block;
  font-weight: bold;
  padding: space(1) space(4) space(1.5);
  margin: space(4) 0;
  color: currentColor;
  border: solid 1px black-lightest;
  border-radius: rounded();
  box-shadow: box-shadow-base;
  text-decoration: none;
}
