.wrapper {
  composes: typography-body from global;
  display: flex;
  align-items: center;
}

.item {
  padding: space(2) space(4);
  margin-right: space(1);
  font-weight: bold;
  text-decoration: none;
  color: currentColor;
}

span.item {
  border-bottom: solid 3px currentColor;
  margin-top: 3px;
}

.chevron {
  padding: space(2);
  margin-right: space(1);
}

.chevron img {
  display: block;
}
