@value breakpoint-sm from '../../breakpoints.module.css';

@value primary-color-lighter from '../../colors.module.css';

.wrapper {
  composes: typography-body from global;
  border-top: solid 1px primary-color-lighter;
  padding: space(4) 0 0;
}

.icon {
  display: none;
  width: size(6);
  margin-right: space(2);
}

.question {
  composes: typography-faqQuestion from global;
  font-weight: bold;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border: 0;
  width: 100%;
  text-align: left;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: space(2) 0;
  line-height: inherit;
  cursor: pointer;
}

.questionText {
  flex: 1;
}

.answer {
  display: none;
  margin: space(2) 0 space(2) space(8);
}

.expanded .answer {
  display: block;
}

@media breakpoint-sm {
  .icon {
    display: block;
  }
}
