@value (
  breakpoint-md,
  breakpoint-lg
) from '../../breakpoints.module.css';

@value primary-color-light from '../../colors.module.css';

.grid {
  display: grid;
  margin-top: space(8);
  gap: size(8);
}

.title,
.text {
  composes: typography-infoGrid from global;
}

.title {
  font-weight: bold;
  margin: size(1) 0 size(2);
}

.text {
  color: primary-color-light;
  margin: 0;
}

@media breakpoint-md {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media breakpoint-lg {
  .grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
