@value breakpoint-md from '../../breakpoints.module.css';
@value white-lighter from '../../colors.module.css';

.outer {
  margin: space(9) 0;
}

.list {
  list-style: none;
  padding: 0;
}

.item {
  color: white-lighter;
  margin: space(4) 0;
}

.active {
  color: white;
}

.video {
  clip-path: circle(50% at center);
  max-width: 30rem;
  margin: space(8) auto;
  pointer-events: none;
}

.video :global .FitViewport-module_inner__3psd1 {
  background-color: transparent;
}

@media breakpoint-md {
  .outer {
    display: flex;
    justify-content: space-between;
    align-items: center;;
  }

  .list {
    position: relative;
    list-style: none;
    margin-left: -2rem;
    margin-right: space(8);
  }

  .item {
    padding-left: 2rem;
    margin: space(6) 0;
  }

  .active {
    background: url(./bullet.svg) left center no-repeat;
  }

  .video {
    width: 40%;
    margin: 0;
  }
}
