.wrapper {
  max-width: 945px;
  margin: 0 auto;
}

.wrapper h6,
.wrapper {
  font-size: 20px;
  line-height: 1.4;
}

.wrapper h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 66px;
  line-height: 1;
  text-align: center;
}

.wrapper h4 {
  margin: 2em 0 1em;
}

.wrapper h6 {
  text-align: center;
}

.wrapper p {
  margin: 1em 0;
}

.wrapper p:has(:global(.inline-image)),
.wrapper p:has(:global(.aligncenter)) {
  text-align: center;
}

.wrapper > img,
.wrapper p > img,
.wrapper i > img,
.wrapper a > img {
  max-width: 100%;
  height: auto;
}

.wrapper p > br + img,
.wrapper > img {
  margin: 1em 0;
}

.wrapper a {
  color: var(--content-link-color);
}

.wrapper iframe {
  border: 0;
}

@media (max-width: 600px) {
  .wrapper h1 {
    font-size: 40px;
  }
}
