@value (
  primary-color, on-primary-color,
  secondary-color, on-secondary-color
) from '../../colors.module.css';

@value box-shadow-base from '../../shadows.module.css';

.wrapper {
  display: flex;
}

.cta {
  display: block;
  padding: space(2) space(6);
  composes: typography-cta from global;
  text-decoration: none;
  font-weight: bold;
  background-color: primary-color;
  color: secondary-color;
  border-radius: rounded();
  box-shadow: box-shadow-base;
}

.cta:hover:not(:active) {
  opacity: 0.92;
}

.onPrimary {
  background-color: secondary-color;
  color: on-secondary-color;
}

.onSecondary {
  background-color: primary-color;
  color: on-primary-color;
}

.layout-centerRagged {
  justify-content: center;
}
