.outer {
  overflow: hidden;
  display: flex;
  width: 100%;
}

.inner {
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  animation: shift 50s linear infinite;
  flex-shrink: 0;
}

.style-staggered > :nth-child(2n+1) {
  display: block;
  margin-top: size(14);
}

@keyframes shift {
  0% {
    transform: translateX(0)
  }

  100% {
    transform: translateX(-100%)
  }
}
