@value breakpoint-sm from "../../breakpoints.module.css";

.wrapper {
  position: relative;
  pointer-events: none;
  height: 0;
}

.clip {
  z-index: -1;
  transform: translateY(-50%);
  overflow: hidden;
}

.wrapper img {
  max-height: 20vw;
}

.verticalAlignment-top .clip {
  transform: translateY(0);
}

.clickHandle {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: auto;
  background-color: #444;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 15px;
  height: 30px;
  opacity: 0.2;
  z-index: 1;
  cursor: default;
}

.wrapper:hover .clickHandle {
  opacity: 0.5;
}

.alignment-right {
  text-align: right;
}

.alignment-full img {
  width: 100%;
  min-width: 500px;
}

.hideOnPhone {
  display: none;
}

@media breakpoint-sm {
  .hideOnPhone {
    display: block;
  }
}
